@import "./common";

.contact {
  &__header {
    text-align: center;
    font-family: 'DM Sans',sans-serif;
    font-size: 80px;
    line-height: 92px;
    margin: 20px 60px 10px;
    font-weight: 700;
    width: 100%;

    &--special {
      padding-right: 10px;
      padding-left: 10px;
      background-color: rgba(121, 97, 183, 0.17);
    }

    &--dot {
      color: #7961b7;
      font-size: 100px;
    }
  }

  &__body {
    @include text-body;
  }

  &__btn {
    padding: 11px 33px;
    border-radius: 35px;
    background-color: #4e4e5c;
    transition: background-color 150ms;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;

    &:hover {
      background-color: #7961b7;
    }
  }

  &__w-btn {
    display: inline-block;
    color: #fff;
    border: 0;
    text-decoration: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 991px) {
  .contact {
    &__header {
      font-size: 66px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 479px) {
  .contact {
    &__header {
      font-size: 53px;
      line-height: 60px;
      text-align: center;
    }
  }
}
