.featured {
  max-width: 1138px;
  margin-top: 0;
  margin-right: 80px;
  margin-left: 80px;

  &__container {
    margin-right: auto;
    margin-left: auto;
    padding-top: 72px;
    padding-bottom: 72px;
  }

  &__layout-mobile {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    margin-top: 30px;
    padding-right: 0;
    justify-content: space-around;
    justify-items: center;
    align-items: end;
    align-content: end;
    grid-auto-flow: column;
    grid-template-rows: auto auto auto;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 24px;

    &--heading {
      display: block;
      font-size: 40px;
      margin-top: 24px;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      font-family: 'DM Sans', sans-serif;
      line-height: 48px;
      font-weight: 700;
      text-align: center;
      letter-spacing: -2px;
    }
  }

  &__image {
    padding-right: 20px !important;
    padding-left: 20px !important;
    border: 0 !important;
    max-width: 100% !important;
    vertical-align: middle !important;
    display: inline-block !important;
  }
}

@media screen and (max-width: 479px) {
  .featured {
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;

    &__container {
      padding-bottom: 72px;
    }

    &__image {
      width: 150px;
    }

    &__title {
      margin-right: 0;
      margin-left: 0;
      padding-right: 5px;
      padding-left: 5px;

      &--heading {
        margin-top: 0;
        line-height: 41px;
        font-size: 40px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .featured {
    max-width: 728px;

    &__container {
      margin-right: 0;
      margin-left: 0;
    }

    &__title {
      &--heading {
        width: 100%;
      }
    }
  }
}
