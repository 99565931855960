@import "./common";

.insights {
  &__container {
    max-width: 1138px;
    margin: 0 auto 100px;
  }

  &__content {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    margin-top: 24px;
    font-family: 'DM Sans',sans-serif;
    font-size: 34px;
    line-height: 48px;
    font-weight: 700;
    letter-spacing: -2px;

    &--heading {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 40px;
      line-height: 46px;
    }

    &--body {
      @include text-body;
      letter-spacing: normal;
    }

    &--layout {
      margin-top: 90px;
      margin-right: 220px;
      margin-left: 220px;
      grid-column-gap: 40px;
      grid-row-gap: 40px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      display: grid;
      grid-auto-columns: 1fr;
    }
  }

  &__report {
    margin-left: 0;
    padding-left: 0;

    &--img {
      border: 0;
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
    }

    &--heading {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0;
      margin-top: 20px;
      margin-bottom: 10px;
      line-height: 30px;
    }
  }
}

@media screen and (max-width: 991px) {
  .insights {
    &__container {
      max-width: 728px;
    }
    &__content {
      &--layout {
        margin-right: 25px;
        margin-left: 25px;
        justify-content: center;
        justify-items: baseline;
        align-items: baseline;
        align-content: space-around;
      }

      &--heading {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .insights {
    &__container {
      max-width: 310px;
      margin-bottom: 72px;
    }

    &__content {
      &--layout {
        grid-template-columns: 1fr;
      }
    }
  }
}
