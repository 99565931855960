@import url('https://fonts.googleapis.com/css?family=Montserrat');

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.015em;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit; /* otherwise clicked text is purple which is not desired */
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input:-internal-autofill-selected {
  background-color: transparent;
}

table {
  text-align: left;
}

.textRight {
  text-align: right;
}

@import 'rc-slider/assets/index.css';

.rc-slider-dot {
  display: none; /* do not show dots on breakpoint of slide bar which has impact only on room class filter */
}

.rc-slider-track {
  background-color: #5d11ff;
}

.rc-slider-handle {
  border: 2px solid #222222;
  /* background: #5D11FF; */
  width: 20px;
  height: 20px;
  margin-top: -8px;
}

.rc-slider-handle:active,
.rc-slider-handle:hover,
.rc-slider-handle-click-focused {
  border-color: #5d11ff;
  box-shadow: 0 0 4px #5d11ff;
}

.rc-slider-rail,
.rc-slider-step,
.rc-slider-track {
  height: 3px;
}

.rc-slider-disabled {
  background-color: transparent !important;
}

.rc-slider-disabled > .rc-slider-handle:active,
.rc-slider-disabled > .rc-slider-handle:hover {
  border-color: #cccccc;
  box-shadow: 0 0 4px #cccccc;
}

/* ref: https://community.hubspot.com/t5/APIs-Integrations/Chat-remove-important-CSS-rules-from-chat-container/td-p/236572 */
body div#hubspot-messages-iframe-container {
  bottom: 48px !important;
}

h1,
h2,
p,
pre {
  margin: 0;
}
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}