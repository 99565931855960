.navigation {
  width: 100%;
  height: 100%;
  max-width: 1138px;
  min-height: 85px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-link {
  font-size: 15px;
  font-weight: 600;
  color: #4e4e5c;
  text-decoration: none;

  &:hover {
    font-family: Poppins,sans-serif;
    color: #7961b7;
  }
}

.footer {
  max-width: 1138px;
  margin-left: auto;
  margin-right: auto;
  &__top {
    display: flex;
    width: 100%;
    max-width: 1100px;
    margin-bottom: 50px;
    padding-top: 64px;
    padding-bottom: 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #dbdbdb;
  }
  &__bottom {
    display: flex;
    width: 100%;
    max-width: 1100px;
    margin-top: 70px;
    padding-top: 0;
    padding-bottom: 50px;
    justify-content: space-between;
    border-top: 1px #dbdbdb;

    &--legal-info {
      margin-top: 0;
      margin-bottom: 0;
      opacity: 1;
      font-family: Poppins, sans-serif;
      color: #ceced3;
      font-size: 12px;
      line-height: 22px;
      font-weight: 600;
      letter-spacing: 2px;
    }

    &--link {
      display: flex;
      grid-column-gap: 50px;
      grid-row-gap: 50px;
    }
  }
  &--link {
    display: flex;
    margin-bottom: 15px;
    opacity: 0.8;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    font-family: Poppins, sans-serif;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
  }
}

@media screen and (min-width: 1440px) {
  .navigation,
  .footer {
    max-width: 1280px;
  }
}

@media screen and (max-width: 991px) {
  .navigation {
    min-height: 65px;
  }

  .footer {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    &__top {
      flex-direction: column;
      align-items: flex-start;
    }
    &__bottom {
      flex-direction: column;

      &--link {
        display: flex;
        margin-top: 20px;
        grid-column-gap: 50px;
        grid-row-gap: 50px;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .footer {
    &__top {
      display: inline-block;
    }
    &__bottom {
      margin-top: 0;
      flex-direction: column;
      align-items: flex-start;
      text-align: center;

      &--legal-info {
        color: #ceced3;
        line-height: 130%;
        text-align: left;
      }

      &--link {
        display: flex;
        margin-top: 22px;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
      }
    }
  }
}

