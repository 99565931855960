@mixin text-body {
  margin-top: 0;
  margin-right: 60px;
  margin-left: 60px;
  padding-right: 0;
  padding-left: 0;
  font-family: Poppins,sans-serif;
  color: #686875;
  font-size: 16px;
  line-height: 160%;
  font-weight: 400;
  text-align: center;
}
