.questions {
  &__heading {
    margin-bottom: 40px;
    text-align: center;
    margin-top: 20px;
    font-size: 38px;
    line-height: 44px;
    font-weight: 700;
  }

  &__container {
    max-width: 1138px;
    padding-right: 40px;
    padding-left: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  &__item {
    overflow: hidden;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 4px;
  }

  &__accordion {
    display: flex;
    padding: 20px 25px;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    transition: background-color .2s;
    font-size: 18px;
    cursor: pointer;

    &--subtitle {
      font-family: 'DM Sans',sans-serif;
      color: #27272e;
      font-size: 20px;
      font-weight: 700;
    }

    &--pane {
      overflow: hidden;
      border-bottom: 1px solid #e6e6e6;
      background-color: #fff;
      height: auto;

      &-content {
        margin-right: 25px;
        margin-left: 25px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      &-text {
        font-family: Poppins,sans-serif;
        color: #686875;
        font-style: normal;
        font-weight: 400;
        text-align: left;

        p {
          margin-top: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .questions {
    &__container {
      max-width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }

    &__item {
      padding-right: 0;
      padding-left: 0;
    }

    &__accordion {
      overflow: hidden;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .questions {
    &__container {
      max-width: 728px;
    }

    &__accordion {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}
